/**
 * Theme global.js
 */

function init() {
    'use strict';

    //

}

document.addEventListener('DOMContentLoaded', init);

(function ($) {

    $(document).ready(function () {
        $('.hamburger--stand').on('click', function (e) {
            $(this).toggleClass('is-active');
            $('#mobile-menu').slideToggle(500);
            $('header').toggleClass('mobile-closed mobile-open');
            $(window).scrollTop(0);
        });

        if ($('#mobile-menu .menu-item-has-children').length) {
            $('#mobile-menu .menu-item-has-children > a').append('<i class="angle-right"></i>');
        }

        if ($('#mobile-menu .current-menu-item').length) {
            $('#mobile-menu .current-menu-item > a').prepend('<i class="dot"></i>');
            $('#mobile-menu .current-menu-ancestor > a').prepend('<i class="dot"></i>');
        }

        $('#mobile-menu i').on('click', function (e) {
            e.preventDefault();
            if (e.target === $(this)[0]) {
                $(this).toggleClass('open');
                $(this).parent().siblings('.sub-menu').slideToggle(150);
            }
        });

        $('header .search, .menu-search a').on('click', function (e) {
            e.preventDefault();
            $('.search-container').slideToggle(150);
        });

        $('header .site-menu-bar .network').on('click', function (e) {
            $('header .site-menu .content').toggleClass('active disabled');
        });

        $('#startdate').datepicker({
            altField: '#displayStartDate',
            altFormat: 'yymmdd',
            format: 'mm-dd-yy'
        });

        $('#enddate').datepicker({
            altField: '#displayEndDate',
            altFormat: 'yymmdd',
            format: 'mm-dd-yy'
        });


        //Set course search form values by url parameters
        let url = new URL(document.location);
        let params = url.searchParams;
        let area = params.get('area');
        let type = params.get('type');
        let organizer = params.get('organizer');
        let language = params.get('language');
        $('#area').val(area);
        $('#type').val(type);
        $('#organizer').val(organizer);
        $('#language').val(language);

        $('#showallprojects').on('change', function () {
            const params = new URLSearchParams(window.location.search)
            params.set('showallprojects', this.checked)
            window.location.search = params
        });

        //FAQ block
        $('.toggle').click(function (e) {
            $(this).parent().siblings().find('.toggle').removeClass('active');
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
            } else {
                $(this).addClass('active');
            }
            if ($(this).next().hasClass('show')) {
                $(this).next().removeClass('show');
                $(this).next().slideUp(350);
            } else {
                $(this).parent().siblings().find('.answer').removeClass('show');
                $(this).parent().siblings().find('.answer').slideUp(350);
                $(this).next().toggleClass('show');
                $(this).next().slideToggle(350);
            }
        });

        //Material bank attachments
        let files = $('.attachments .files');
        $('#show-more-files').on('click', function (e) {
            files.css('max-height', 'none');
            $(this).css('display', 'none');
        });

        //Order monitor
        $('.order-monitor .chevron-right').on('click', function () {
            $(this).siblings('.participants').slideToggle(500);
            $(this).toggleClass('closed open');
        });

        //eStep
        $('.view-type').on('click', function () {
            $('.network-courses').toggleClass('default compact');
        });
    });

    $(window).scroll(function (event) {
        let scroll = $(window).scrollTop();
        if (scroll > 20) {
            $('header').addClass('scrolled')
        } else {
            $('header').removeClass('scrolled');
        }
    });


    //Push course detais into datalayer
    if (typeof dataLayerObj !== 'undefined') {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'course_start': dataLayerObj.course_start,
            'course_end': dataLayerObj.course_end,
            'application_start': dataLayerObj.application_start,
            'application_end': dataLayerObj.application_end,
            'interview': dataLayerObj.interview,
            'location': dataLayerObj.location,
            'area': dataLayerObj.area,
            'type': dataLayerObj.type,
            'organizer': dataLayerObj.organizer,
            'expertise': dataLayerObj.expertise,
            'expertise2': dataLayerObj.expertise2,
            'expertise3': dataLayerObj.expertise3,
            'expertise4': dataLayerObj.expertise4,
            'expertise5': dataLayerObj.expertise5,
            'expertise6': dataLayerObj.expertise6,
            'language': dataLayerObj.language,
            'price': dataLayerObj.price,
            'link': dataLayerObj.link,
        });
    }

    //Scroll to person by letter click
    $('#letters a').on('click', function () {
        let letter = $(this).data('id');
        $('.alphabet').removeClass('active');
        $('.alphabet[data-id="' + letter + '"]').addClass('active');
        let header = 0;
        if ($(window).width() < 1024) {
            header = $('.mobile-closed').outerHeight() + 20;
        }
        $([document.documentElement, document.body]).animate({
            scrollTop: $("#" + letter).offset().top - header
        }, 500);
    });

})(jQuery);
